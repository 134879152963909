import React from "react"

class EarnReward extends React.Component {

  render() {
    return (

      <section className="process-of defistak pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center"><span className="bluecolor">Earn Rewards</span> Through DeFi Staking Platform Development
            </h2>
            <p className="text-center">
            As a renowned DeFi staking platform development company, we assist you in offering loyalty benefits to users effectively. Here are the glimpses of rewards your DeFi staking platform can offer to the users.
            </p>
            <div className="d-lg-flex add-one">
              <div className="square" >
                <h3>Basic </h3>
                <p className="pharagraph">
                This is the basic level of rewards users earn for staking their assets in the DeFi ecosystem. The rewards are basically some percentage of the total value of assets staked. The user will receive the rewards directly in the user's wallet.
                </p>
              </div>
              <div className="square">
                <h3>Progressive</h3>
                <p className="pharagraph">
                This is a type of reward similar to mutual fund investment where the fund owner manages the majority of the capital. When the user stakes assets, profit will be reinvested into the assets and rewards will be distributed to the users.
                </p>
              </div>
              <div className="square">
                <h3>Prominent</h3>
                <p className="pharagraph">
                This type of reward gives the users administrative responsibilities along with the benefits. So the user who stakes their asset will have rewards for staking as well as supervising authority to oversee DeFi staking platform transactions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

    )
  }
}

export default EarnReward
