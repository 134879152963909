import React from 'react';
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import BannerCrypto from '../components/defi-staking/Banner';
import Whatis from '../components/defi-staking/Whatis';
import FaqSection from '../components/defi-staking/FaqSection';
import TechStack from '../components/defi-staking/TechStack';
import WorkProcess from '../components/defi-staking/WorkProcess';
import BenefitsOf from '../components/defi-staking/BenefitsOf';
import EarnReward from '../components/defi-staking/EarnReward';
import UserFriendly from '../components/Cryptoexchange/UserFriendly';
import UseCase from '../components/defi-staking/UseCase';
import WhyChoose from '../components/defi-staking/WhyChoose';

const DefiStaking = () => (
  <section className="cryptoex">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/defi-staking-platform-development/" />
        <title>DeFi Staking Platform Development - Coinsclone</title>
        <meta name="description" content="Coinsclone offers DeFi Staking Platform Development services to develop a high performance DeFi staking platform that can deliver higher ROI for businesses." />
        <meta name="keywords" content="DeFi Staking Platform Development" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="DeFi Staking Platform Development - Coinsclone" />
        <meta property="og:description" content="Coinsclone offers DeFi Staking Platform Development services to develop a high performance DeFi staking platform that can deliver higher ROI for businesses." />
        <meta property="og:url" content="https://www.coinsclone.com/defi-staking-platform-development/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/defi-staking/defi-staking-platform-development.webp" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone offers DeFi Staking Platform Development services to develop a high performance DeFi staking platform that can deliver higher ROI for businesses." />
        <meta name="twitter:title" content="DeFi Staking Platform Development - Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/defi-staking/defi-staking-platform-development.webp" />
      </Helmet>
      
      <BannerCrypto />
      <div className="breadcrumb wallet-banner mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | DeFi Staking Platform Development</span>
        </div>
      </div>
      <Whatis />
      <BenefitsOf />
      <EarnReward />
      <WorkProcess />
      <UseCase />
      <WhyChoose />
      <TechStack />
      <FaqSection />
      <UserFriendly /> 
    </Layout>
  </section>
)

export default DefiStaking
