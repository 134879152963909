import React from 'react'


const WorkProcess = () => {

  return (
    <section className="customs defistak pt-100 mb-0">
      <div className="container">
        <div className="row">
            <h2 className="heading-h2 text-center">DeFi Staking Platform Development <span className="bluecolor">Work Process - We Follow</span></h2>
            <p className='text-center'>Coinsclone as a renowned DeFi staking development company follows a client-centric development process that can uncover maximum opportunities for business owners. Here is the process involved in our DeFi staking platform development.</p>
            <div className='d-flex flex-wrap justify-content-center top'>
                <div className='custom-blk'>
                    <h3>Ideation Phase</h3>
                    <p class="pharagraph">In this phase, our expert team will listen to your thoughts, ideology, services, and target audience to prepare a roadmap. Our DeFi consulting services will provide the required guidance in standardizing your business principles.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Discovery Stage</h3>  
                    <p class="pharagraph">This is the stage where our expert team will prepare a clear road map to achieve the target goals. The process involves numerous analyses, and discussion sessions among the expert teams to eradicate the challenges.</p>
                </div>
                <div className='custom-blk'> 
                    <h3>Requirement Collections</h3>
                    <p class="pharagraph">In this stage, we gather the prerequisites needed to transform your ideology into business. Our team will look at the relevant tools, tech stacks, project completion time, and challenges involved in DeFi staking platform development.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Design and Development</h3>
                    <p class="pharagraph">Our professional experts will prefer the most appropriate solution to build a future-ready DeFi staking platform. The experienced developers will design a UI/UX layout that can adapt the contemporary features and functionalities to meet your business goals.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Testing Phase</h3>
                    <p class="pharagraph">In this stage, we apply stringent testing strategies to ensure the DeFi staking platform is free from security vulnerabilities and performance lags. Our internal auditing process sorts out minor bugs and ensures hassle-free staking services.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Deployment Stage</h3>
                    <p class="pharagraph">Once the quality analysis is finished, our professionals will arrange the necessities for deploying the DeFi staking platform. Meeting clients’ satisfaction and ensuring necessary guidance are the top priority in the deployment stage.</p>
                </div>
            </div>
        </div> 
      </div>
    </section>
  )
}

export default WorkProcess