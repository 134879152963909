import React from 'react'


const UseCase = () => {

  return (
    <section className="typeof defistak pt-100 mb-0">
      <div className='gray-bg'>
        <div className="container">
          <div className="row">
              <h2 className="heading-h2 text-center"><span className="bluecolor">Use Cases</span> Of DeFi Staking
              </h2>
              <p className="text-center">
              Our DeFi staking platforms are highly applicable to diverse use cases. Check out the glimpses of use cases herewith.
              </p>
          </div>
          <div className='row popular we-offer'>
              <div className='col-md-12 col-lg-3'>
                  <h3>Farming Services</h3>
                  <p className='pharagraph'>DeFi Yield farming allows investors to contribute to the DeFi ecosystem directly. The liquidity offered by the investors will be utilized for DeFi platform operations.</p>
              </div>
              <div className='col-md-12 col-lg-3'>
                  <h3>NFT Staking</h3>
                  <p className='pharagraph'>In NFT staking, investors stake their Non-Fungible Tokens for liquidity. The investor will receive rewards in the form of the same NFTs or the native token of the platform.</p>
              </div>
              <div className='col-md-12 col-lg-3'>
                  <h3>DeFi Lending and Borrowing</h3>
                  <p className='pharagraph'>DeFi lending and borrowing platforms facilitate crypto loans to crypto investors with collateral by staking crypto assets. Any crypto asset can be staked as collateral.</p>
              </div>
              <div className='col-md-12 col-lg-3'>
                  <h3>Asset Diversification</h3>
                  <p className='pharagraph'>DeFi staking platform holds different cryptocurrencies that give cryptocurrency holders diverse chances of staking options, thus increasing the profit potential.</p>
              </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default UseCase