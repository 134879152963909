import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">  
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side mb-0 mb-md-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'>DeFi Staking</span> Platform Development Company</h1>
                <p className='pharagraph mb-0'>Coinsclone as a leading DeFi staking platform development services helps you explore unparalleled profit generation with DeFi development services. Experience the contemporary features and mind-blowing performance with our DeFi staking development that fulfills your business expectations.</p>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Talk to our Experts</a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/defi-staking/defi-staking-platform-development.webp"
                alt="DeFi Staking Platform Development"
                className='d-none d-md-block float-end'
                width={608}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection