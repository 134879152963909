import React from 'react'

class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis pt-0">
        <div className='gray-bg'>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2">DeFi Staking Platform Development - <span className="bluecolor">An Overview</span></h2>
              <p className="pharagraph">DeFi Staking Platform Development is the process of creating a fully functional DeFi staking platform with astonishing key features and development methods. We provide custom solutions that can accelerate your DeFi staking services to garner passive income. With a deeper understanding of blockchain consensus, our experts have coded the DeFi staking software to be more reliable, compatible, and consistent under any circumstance.</p>
              <p className='pharagraph mb-0'>
              Our DeFi staking platform development services help you bridge the gap between your desire to make a profit and the provisions of building a solid business empire. We create customized DeFi staking platforms that are highly optimized to meet your business goals effectively.
              </p>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis